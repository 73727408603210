.m-resource-highlight-v2 {
    max-width:750px;
    display: block;
    padding:var(--spacing-md);
    transition: background 0.3s ease-out, box-shadow 0.3s ease-out, -webkit-box-shadow 0.3s ease-out;
    background:rgba(255,255,255,0);
    border-radius: var(--border-radius-img-default);
    &__content {
        padding:0 0 var(--spacing-xs) 0;
        &--padded {
            padding: var(--spacing-md);
        }
    }
    &__img-wrapper {
        position: relative;
        margin-bottom:var(--spacing-md);
    }
    &__img-wrapper::after {
        display: block;
        content: '';
        /* 16:9 aspect ratio */
        padding-bottom: 56.25%;
        }
    &__img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: var(--border-radius-img-default);
    }

    &__date  {
        @extend .a-medium-text;
    }

    &__tag {

       @include eyebrow;
        display:block;
        padding:0 0;
        // margin-bottom:0.5rem;
        &--yellow {
            background-color:#FBAE30;
        }
        &--purple {
            background-color:#6C00D6;
        }
        &--red {
            background-color: #F0705F;
        }
        &--green {
            background-color:#2FAD69;
        }
    }
    &__title {
        @include heading-4;
        // margin-top:1rem !important;
        color:var(--text-body-text);
    }
    &__excerpt {
        @include body;
        color:var(--text-body-text);
    }

    &__cta {
        color:var(--text-body-text);
        transition: all 0.3s ease-out;
    }

    &--inverted {
        .m-resource-highlight-v2__tag {
            color:rgba(255,255,255,0.7) !important;
        }
        .m-resource-highlight-v2__title {
            color:rgba(255,255,255,1) !important;
        }
    }

    &--compact {
        .m-resource-highlight-v2__title {
            @include heading-5;
        }
        .a-medium-text {
            font-size:var(--font-md-size);
        }
    }
    &--aspect-9-10 {
        .m-resource-highlight-v2__img-wrapper {
            aspect-ratio: 9 / 10;
        }
    }

}

.m-resource-highlight-v2:hover {
    background:var(--surface-body-bg);
    -webkit-box-shadow: var(--shadow-light);
    box-shadow: var(--shadow-light);


    .m-resource-highlight-v2__cta {
        color:var(--color-action-color);
        font-weight:var(--font-bold-weight);
    }
}

.m-resource-highlight-v2--inverted:hover{
    background: var(--surface-high-contrast-hover);
    -webkit-box-shadow: var(--shadow-light);
    box-shadow: var(--shadow-light);
}

