﻿html {
    scroll-behavior: smooth;
}
body {
    background-color:$bg-color;
    padding-top:0;
    font-size:16px;
    // scroll-behavior: smooth;
    :target { scroll-margin-top: 180px; scroll-margin-bottom: 50px; }
    :focus { scroll-margin-top: 180px; scroll-margin-bottom: 50px; }
}

a:not([class]):hover {
    color:darken($action-color, 10%);
    text-decoration: underline !important;
}

.no-scroll
{
    overflow:hidden;
    height:100%;
}


.container{
    padding-top:50px;
    padding-bottom:50px;
}
.btn
{
    @include transition-all;
    border-radius:3px;
    border:none;
    text-align: center;
}
.btn-dark
{
    background-color:rgba(0,0,0,0.7);
    color:rgba(255,255,255,1);
}
.btn-dark:hover
{
    // background-color:$dark-gray;
    color:rgba(255,255,255,1);
    background-color:$action-color;
}
.btn-light
{
    background-color:rgba(255,255,255,0.7);
    color:$dark-gray;
}
.btn-light:hover{
    // background-color:rgba(255,255,255,1);
    background-color:$action-color;
    // color:$dark-gray;
    color:white;
}

.btn-primary
{
    background-color:$action-color;
    color:white;

    min-width: 150px;
}

.btn-link
{
    background-color:transparent;
    text-align: left;
    color:$action-color;
    display: block;
    margin:10px 0px;
    padding: 10px 0px;
}


.img-circle
{
    border-radius:50% 50%;
}
.card-list
{
    position:relative;
    top:-100px;
}

#servicenow-card .card-header
{
     background-image:url('../images/image-servicenow.jpg');
}
#servicedesk-card .card-header
{
     background-image:url('../images/image-servicedesk.jpg');
}
#autism2work-card .card-header
{
     background-image:url('../images/image-autism2work.jpg');
}
#applicationsupport-card .card-header
{
     background-image:url('../images/image-applicationsupport.jpg');
}
#agileservices-card .card-header
{
     background-image:url('../images/image-agileservices.jpg');
}
#managedservices-card .card-header
{
     background-image:url('../images/image-managedservices.jpg');
}

.feature-block
{
    // display: flex;
    h3
    {
        margin-top:5px;
        line-height:2rem;
    }
    margin-bottom:0px;
    margin-top:60px;
}


// Image Dividers

.image-section
{
    background-size:cover;
    padding-top:90px;
    padding-bottom:70px;
    background-image:url('../images/hero-careers.jpg');
    background-position: 50% 50%;
}

.divider-1
{
    background-image:url('../images/hero-careers.jpg');
    background-position: 50% 50%;
}

.divider-2
{
    background-image:url('../images/section-bg-about.jpg');
    background-position: 50% 50%;
}

// Form Styles

// input:not([type]), input[type="email"], input[type="number"], input[type="password"], input[type="tel"], input[type="url"], input[type="text"], textarea.form-control, select {
//     background-color:#efefef;
//     border:none;
//     color:$dark-gray;
//     font-weight: 700;
//     background-image: none !important;
// }

// .form-group select
// {
//     display: block;
//     padding: .5rem .75rem;
//     width:100%;
// }

// input:not([type]), input[type="email"]:focus, input[type="number"]:focus, input[type="password"]:focus, input[type="tel"]:focus, input[type="url"]:focus, input[type="text"]:focus, textarea.form-control:focus, select:focus {
//     background-color:#efefef;
//     border:none;
//     color:$dark-gray;
//     background-image: none !important;
// }


label
{
    margin-bottom:0.2rem;
    margin-top:0.8 rem;
}

.body--gray {
    background-color: $lightest-gray;
}

p>strong {
    font-weight:$bold;
}

.person-profile
{
    img
    {
        margin: 20px auto;
        display: block;
    }
    padding: 3rem;
}

.page-section-dark
{
    background-color:$dark-blue;
    padding-top:100px;
    padding-bottom:100px;
}

.page-section-light
{
    background-color:$lightest-gray;
    padding-top:100px;
    padding-bottom:100px;
}

blockquote
{
    border-left: 3px solid rgba(255,255,255,0.6);
    padding-left: 30px;
    p
    {
        font-size:150%;
        font-weight:100;
    }
    footer
    {
        font-size:150%;
        opacity:0.7;        // padding-left:5rem;
    }
}

blockquote.large
{
    border-left: 3px solid rgba(255,255,255,0.6);
    padding-left: 30px;
    p
    {
        font-size:300%;
        font-weight:100;
        font-style:italic;
        font-family: $header-font;
    }
    footer
    {
        font-size:150%;
        opacity:0.7;        // padding-left:5rem;
    }
}

.h-muted
{
    font-size:75%;
    color:$medium-gray;
}

.h-inset-lg
{
    padding-left:5rem;
    padding-right:5rem;
}

.h-hide-on-large
{
    @media (min-width: 993px) {
        opacity:0;
    }
}

.h-show-on-small
{
    @media (min-width: 767px) {
        display:none !important;
    }
}
.h-hide-on-small
{
    @media (max-width: 767px) {
        display:none !important;
    }
}

.h-show-on-large
{
    @media (max-width: 993px) {
        display:none !important;
    }
}

.h-show-on-medium
{
    @media (max-width: 767px) {
        display:none !important;
    }
}
.h-hide-on-medium
{
    @media (min-width: 768px) {
        display:none !important;
    }
}

.h-centered-mobile {
    @media (max-width: 767px) {
        text-align: center;
    }
}

.h-vert-center-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.h-sr-only {
    position: absolute !important;
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0 !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden;
  }




#skipToMenu
{
    z-index: 9999999;
}

.a-site-chat {
    position: fixed;
    bottom:32px;
    right:32px;
    width:48px;
    height:48px;
    &__icon {
        width:100%;
        border-radius:100px;
        object-fit: contain;
        -webkit-box-shadow: 0 2px 18px 2px rgba(0,0,0,0.25);
        -moz-box-shadow: 0 2px 18px 2px rgba(0,0,0,0.25);
        box-shadow: 0 2px 18px 2px rgba(0,0,0,0.25);
    }
    z-index: 9998;
}

ol:not([class])>li {
    margin-bottom: 1rem;
}
// Squiz-specific CSS
div[id^="component_"]{
    // ul {
    //     list-style: outside;
    //     list-style-type: none;
    //     padding:0;
    //     margin-left:30px;
    //     li
    //     {
    //         margin-bottom:.25rem;
    //         position: relative;
    //         list-style-type: none !important;
    //     }
    //     li::before
    //     {
    //         content:'';
    //         display:inline-block;
    //         height:1em;
    //         width:1em;
    //         // TO-DO: SORT OUT PATH
    //         background-image:url('../images/right-arrow.png');
    //         background-size:contain;
    //         background-repeat:no-repeat;
    //         position: absolute;
    //         left:-30px;
    //         top:3px;
    //     }
    ul {
        @extend .m-checklist;
        li {
            @extend .m-checklist__item;
        }
    }

    ol {
        padding:0;
        margin-left:30px;
        >li {

            >ol {
                list-style-type: lower-alpha;
                padding-top:0.25rem;
                padding-bottom:0.5rem;
                >li {
                    >ol {
                        list-style-type:lower-roman;
                    }
                }
            }
        }
    }


    img:not([class]) {
        @extend .img-fluid;
    }
    table:not([class]), .o-table {
        // border:solid 1px $light-gray;
        border-radius: 10px;
        td {
            padding:.75rem 1rem;
            border-bottom:solid 1px $light-gray;
        }
        th {
            padding: 1rem 1rem;
            border-bottom:solid 2px darken($bright-blue, 3%);
            color: darken($bright-blue, 20%);
            background-color:lighten($bright-blue, 53%) !important;
        }
    }

    .o-table {
        &--striped {
            tr:nth-child(even) {
                background-color: $lightest-gray;
              }
        }
    }

    h2 {
        margin-top:2.5rem;
    }
    h2:first-of-type {
        // margin-top:0rem;
    }
    h3 {
        // margin-top:1.75rem;
    }
    h3:first-of-type {
        // margin-top:0rem;
    }
}

// Swiper Overrides
.swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled {
    opacity: 0 !important;
}



// Experimental
// .article-content p {
//     font-size:1.1rem;
// }

// .article-content--first {
//     initial-letter: 2;
// }

// .article-content--first p:first-child:first-letter
// {
//     color: $bright-blue;
//   float: left;
// //   font-family: Georgia;
//   font-size: 60px;
//   line-height: 48px;
//   padding-top: 4px;
//   padding-right: 6px;
//   padding-left: 3px;
// }

article {
    a:not([class]) {
        text-decoration: underline;
    }
}

main {
    a:not([class]) {
        text-decoration: underline;
    }
}


caption {
    color:$medium-gray;
    font-size:0.9rem;
    text-align: center;
}

.h-sticky {
    position: sticky;
    top:150px;
}

//working in global but not in m-form-dropdown.scss
.select {
    border: solid 1px #dedede;
    padding: .5rem 1rem;
    width: 100%;
}
.select:focus-visible
{
     @include focused-offset;
}

.a-bold {
    font-weight: $bold;
}


// Accessibilty Styles
a:not([class]):focus-visible {
    // background-color: $yellow;
    // border-bottom: solid 2px #111111;
    // outline:none;
    @include focused-no-offset-thin;
}
a:focus-visible {
    @include focused-offset;
}

input[type=text]:focus-visible {
    @include focused-offset;
}

