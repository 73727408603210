.o-logo-cloud
{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    &__item
    {
        margin:var(--spacing-md) var(--spacing-md);
        padding:var(--spacing-md);
        align-content: center;
        
        // flex-basis:500px;
        // flex-basis: 20%;
        transition: all ease-out 0.2s;
    }
    &__item:hover
    {
        background-color:var(--surface-low-contrast);
    }
    &__img
    {
        height:80px !important;
    }
}