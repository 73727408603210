.o-page-nav {

    background-color:var(--surface-low-contrast);
    // -webkit-box-shadow: 0px 0px 22px -18px rgba(0,0,0,1);
    // -moz-box-shadow: 0px 0px 22px -18px rgba(0,0,0,1);
    // box-shadow: 0px 10px 22px -18px rgba(0,0,0,0.3);
    border-top:solid 1px var(--line-low-contrast);
    position:fixed;
    z-index:999;
    position:sticky;
    top:80px;
    width:100%;
    border-bottom:solid 1px #efefef;
    @media (max-width: 992px) {
        // display: none;
        overflow-x: hidden;
    }
    &__inner {
        display: flex;
        padding:0rem 24px;
        align-items: center;
        min-height:60px;
        width:100%;
        position: relative;
        justify-content: flex-start;
        @media (max-width: 992px) {
            flex-wrap: nowrap;
            overflow-x: scroll;
            padding-right:80vw;
        }
        &--expanded {
            height:100vh;
        }
    }
    &__primary-nav-list {
        list-style:none;
        display: flex;
        margin-bottom:0;
        padding-left:0;
        @media (max-width: 992px) {
            flex-wrap: nowrap;
        }

    }
    &__nav-item {
        margin-right:var(--spacing-md);
        line-height: var(--line-height-condensed);
        padding-top:var(--spacing-sm);
        padding-bottom:var(--spacing-sm);
        padding:var(--spacing-sm) var(--spacing-md);

        &--dropdown-list {
            position: relative;
        }
        &--active {
            background-color:$lightest-blue;
            .o-navbar__dropdown-list {
                display: block !important;
            }
        }

        &--condensed {
            margin-right:.75rem;
        }
        &--primary {
            background: var(--color-action-color);
        }
        @media (max-width: 992px) {

            padding: 0;


            // margin-bottom:.5rem;
        }
    }
    &__nav-item-link {
        color:var(--text-body-text);
        font-size:var(--font-md-size);
        font-weight:var(--font-default-weight);
        transition: border 0.3s ease-out, color 0.3s ease-out;
        border-bottom: solid 3px transparent;
        display:inline-block;
        @media (max-width: 992px) {
            white-space: nowrap; // Prevent text wrapping
            text-overflow: ellipsis; // Add ellipsis if text overflows
            overflow: hidden; // Ensure overflow text is hidden
            border-bottom: none;
            color: var(--color-action-color);
            margin-top:2px;
            padding:var(--spacing-xs) var(--spacing-sm);
            border-radius:100px;
            border: solid 2px var(--color-action-color);
            background-color: white;
        }
        &--current {
            border-bottom:solid 3px var(--color-action-color);
            @media (max-width: 992px) {
                background-color: var(--color-action-color);
                color: var(--white);
                &:hover {
                    color: var(--white) !important;
                }
                &:active {
                    color: var(--white) !important;
                }
            }
        }

    }
    &__nav-item--primary .o-page-nav__nav-item-link {
        color: var(--text-body-text-inverse);

    }
    &__nav-item--primary:hover .o-page-nav__nav-item-link {
        color:var(--text-body-text-inverse);
        text-decoration: underline;

    }
    &__nav-item-link:hover {
        color:var(--color-action-color);
    }

}
// .o-navbar__inner--expanded {
//     overflow-y: scroll;
//     .o-navbar__primary-nav-list {
//         display: block;
//     }
// }
