.o-page-section
{
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-color:var(--surface-body-bg);
    position: relative;
    &--transparent {
        background-color: transparent;
    }
    &--beige
    {
        background-color:$beige;
    }
    &--light-gray
    {
        background-color:var(--surface-low-contrast);
    }
    &--teal
    {
        background-color:var(--surface-low-contrast-accent);
        color:var(--text-accent-complement);
    }
    &--dark-blue
    {
        background-color: var(--surface-high-contrast);
        color:var(--text-body-text-inverse);
        a {
            color:var(--text-body-text-inverse);
        }
        a:hover {
            color:var(--text-body-text-inverse);
            text-decoration: underline;
        }
    }
    &--dark-gray
    {
        background-color: var(--neutral-300);
        color: var(--text-body-text-inverse);
    }
    &--blue-gradient {
        background: rgb(52,119,129);
        // background: linear-gradient(315deg, rgba(52,119,129,1) 0%, rgba(0,42,93,1) 58%);
        color:var(--text-body-text-inverse);
    }
    &--light-blue
    {
        background-color: $lightest-blue;
    }
    &--low-contrast-accent {
        background-color: var(--surface-low-contrast-accent);
    }
    &--medium-contrast-accent {
        background-color: var(--surface-medium-contrast-accent);
        color: var(--text-body-text-inverse);
    }
    &--bg-img-top
    {
        background-position: center top;
        background-repeat: no-repeat;
    }
    &--bg-bottom-right
    {
        background-position: right bottom;
        background-repeat: no-repeat;
    }
    &--bg-img-cover
    {
        background-size: cover;
    }
    &--bg-img-contain
    {
        background-size: contain;
    }
    &--bg-img-center
    {
        background-position: center center;
        background-repeat: no-repeat;
    }
    &--bg-repeat-x
    {
        background-repeat: repeat-x;
    }

    &--bg-repeat-y
    {
        background-repeat: repeat-y;
    }

    &--collapsed
    {
        display: none;
    }

    &__close-svg {
        height:24px;
        width:24px;
    }

    &__collapse-toggle {
        position: absolute;
        top:50px;
        right:0px;
        cursor: pointer;
        border:none;
        background:transparent;
    }
    &__collapse-toggle:focus-visible {
        @include focused-offset;
    }

    &__content_container
    {
        position:relative;
    }
    &__content-container--full-width
    {
        @extend .container-fluid;
    }
    &__content-container--fixed-width
    {
        @extend .container;
    }
    &__content-container--narrow
    {
        @extend .container;
        max-width:1000px;
    }

    &__content-container--extra-narrow
    {
        @extend .container;
        max-width:800px;
    }
    &__content-container--med-wide-fixed-width
    {
        @extend .container;
        max-width:1440px;
    }
    &__content-container--wide-fixed-width
    {
        @extend .container;
        max-width:1800px;
    }
    &__content-container--white
    {
        background-color: var(--surface-body-bg);
    }
    &__content-container--top-shadowed
    {
        position: relative;
    }
    &__content-container--top-shadowed:before
    {
        position: absolute;
        top: 0; left: 0%;
        width: 100%;
        height: 50vh;
        -webkit-box-shadow: 0px -16px 33px -10px rgba(0,0,0,0.3);
        -moz-box-shadow: 0px -16px 33px -10px rgba(0,0,0,0.3);
        box-shadow: 0px -16px 33px -10px rgba(0,0,0,0.3);
        content: '';
        z-index:-1;
    }
    &__content-container--rounded {
        border-radius:var(--border-radius-lg);
    }
    &__content-container--pt-sm {
        padding-top:15px;
    }
    &__overlay {
        position: absolute;
        top:0;
        left:0;
        height:100%;
        width:100%;
        background: rgb(0,0,0);
        background: linear-gradient(30deg,rgba(0,0,0,.934331) 0,rgba(0,0,0,.667945) 61%,transparent 100%);
        z-index: 0;
    }
}

div.o-page-section:first-of-type
{

}