.o-stat-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    &--left-aligned {
        justify-content: flex-start;
    }
    &__stat {
        margin:0 var(--spacing-md);
        flex: 1 1 0px;
    }
    &__stat:first-of-type {
        margin:0 var(--spacing-md) 0 0;
    }
    &__stat:last-of-type {
        margin:0 0 0 var(--spacing-md);
    }
}