.o-resource-row {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    @media (max-width:767px) { 
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        
     }
    &__highlight {
        margin: 0 var(--spacing-sm);
        
        @media (min-width:767px) { 
            flex: 1 1 0px;
         }

        @media (max-width: 767px) { 
            margin: var(--spacing-xs) 0;
            width: 100%;
         }
    }
    &__highlight:first-of-type {
        margin: 0 var(--spacing-sm) 0 0;
        @media (max-width:767px) { 
            margin-right: 0;
         }
    }
    &__highlight:last-of-type {
        margin: 0 0 0 var(--spacing-sm);
        @media (max-width:767px) { 
            margin-left: 0;
         }
    }
}