.o-shaded-hero
{
    @extend .jumbotron;
    @extend .jumbotron-fluid;
    background-size:cover;
    background-position-x: right;
    background-position-y: center;
    background-color:transparent;
    
    @if $high-contrast == true {
        background-color:$dark-gray;
    }
    background-repeat:no-repeat;
    padding-top:2rem;
    padding-bottom:2rem;
    // height:375px;
    padding-top:75px;
    position: relative;
    background-color:#f5f5f5;
    &--blue {
        background-color:#E8F7FD;
    }
    &--pink {
        background-color: lighten($action-color, 43%);
    }
    @media (min-width: 1000px) { 
        padding-top:100px;
        padding-bottom:5rem;
        // height:250px;
     }
     &__container {
        @extend .container;
        &--med-wide {
            max-width:1400px;
        }
     }
     &__tag {
        color: #0096D1;
        text-transform: uppercase;
        font-weight: 500;
        font-size:14pt;
        &--red {
            color:$action-color;
        }
     }
     &__title {
         color:$dark-gray;
         font-size:2.5rem;
         font-weight:900;
     }
     &__description {
         font-size:1.1rem;
         color:$dark-gray;
         max-width:40rem;
     }
}