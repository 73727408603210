.m-breadcrumb {
    display: flex;
    list-style-type: none;
    list-style-image: url('');
    margin-left: 0;
    padding-left: 0;
    margin-bottom: .5rem;

    &__breadcrumb-item {
        @include body;
        color: var(--color-accent-color);
        margin-right: var(--spacing-xxs);
        display: inline-block;
        max-width: 30ch;
        white-space: nowrap; // Add this line
        text-overflow: ellipsis;
        overflow-x: hidden; // Change from 'clip' to 'hidden'
        padding: 4px 5px;
        margin-right: 0px;

        // Uncomment and correct the media queries if needed
        // @media (max-width: 1350px) {
        //     display: none;
        // }
        // @media (min-width: 1350px) {
        //     max-width: 50ch;
        // }


        &--active {
            color: var(--color-accent-color);
            font-weight: var(--font-default-weight);
            @media (max-width: 1350px) {
                display: inline-block;
            }
        }

        &--active:hover {
            color: var(--color-accent-color) !important;
            font-weight: var(--font-default-weight);
        }

        &::after {
            content: '/';
            font-size: var(--font-md-size);
            margin-left: var(--spacing-xxs);
            color: var(--color-accent-color);
            text-decoration: none !important;
        }
        &:last-child::after {
            content: '' !important;
        }

        &:hover::after {
            text-decoration: none !important;
        }

        &--active::after {
            content: '' !important;
        }


        &:hover {
            color: var(--color-action-color);
            text-decoration: none;
        }
    }

    &__breadcrumb-link {
        color: var(--color-accent-color);
        text-overflow: ellipsis;
        white-space: nowrap; // Changed from 'text-wrap'
        margin-right: 5px;
        font-weight: var(--font-bold-weight);
    }

    &__breadcrumb-link:hover {
        color: var(--color-accent-color);
        text-decoration: underline;
    }

    &--light {
        color: var(--text-accent-complement);

        .m-breadcrumb__breadcrumb-item {

            color: var(--text-accent-complement);

            &::after{
            color: var(--text-accent-complement);
            }
        }
        & .m-breadcrumb__breadcrumb-link  {
            color: var(--text-accent-complement);

            &:hover {
                color: var(--text-accent-complement);
        }
        & .m-breadcrumb__breadcrumb-item--active {
            color: var(--text-accent-complement);
        }

    }
 }
}
