.m-pull-quote
{
    padding-left:var(--spacing-xl);
    padding-right:var(--spacing-xl);
    margin-top:var(--spacing-3xl);
    margin-bottom: var(--spacing-3xl);
    padding-top: 70px;
    background-image:url('../images/content-quote.png');
    background-position: 1.5rem top;
    background-size: 40px 40px;
    background-repeat: no-repeat;
    border-left:none;
    &__text
    {
        font-family: var(--heading-fonts);
        font-size:var(--font-xl-size);
        font-weight:var(--font-bold-weight);
        color: var(--color-accent-color);

    }
    &__source
    {
        font-size:var(--font-md-size);
        color: var(--text-body-text);
        opacity: 1 !important;
    }
    &__name {
        font-weight: var(--font-bold-weight);
        display: block;
    }
    &--teal
    {
        background-image:url('../images/quotes-teal.png');
    }
    &--yellow
    {
        background-image:url('../images/quotes-yellow.png');
    }
    &--purple
    {
        background-image:url('../images/quotes-purple.png');
    }
    &--light {
        background-image:url('../images/content-quote-light-blue.png');
        .m-pull-quote__text {
            color:var(--text-body-text-inverse);
        }
        .m-pull-quote__source {
            color:var(--text-body-text-inverse);
        }
        .m-pull-quote__name {
            color:var(--text-body-text-inverse);
        }
    }

}

