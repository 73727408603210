.m-image-highlight  {
    height:600px;
    max-height:55vh;
    min-height:500px;
    background: transparent center center no-repeat;
    background-size:cover;
    display: block;
    width:100%;
    transition: box-shadow 0.3s ease-out, background 0.3s ease-out, color 0.3s ease-out;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    text-decoration: none;
    padding:var(--spacing-xxl) var(--spacing-xxl) var(--spacing-3xl) var(--spacing-xxl);
    color:white;
    // border-radius: var(--border-radius-img-default);
    @media (max-width:767px){
        height:auto;
        max-height:none;
        min-height:500px;
        padding:var(--spacing-3xl) var(--spacing-xxl) var(--spacing-3xl) var(--spacing-xxl);
    }
    &--short {
        height:400px;
        max-height:55vh;
        min-height:300px;
        @media (max-width:1400px){
            height:100%;
            max-height:none;
            min-height:300px;
        }
    }
    &--tall {
        height:calc(100vh - 225px);
        max-height:none;
        min-height:300px;
        padding-bottom:15vh;
        // @media (max-width:1400px){
        //     height:100%;
        //     max-height:none;
        //     min-height:300px;
        // }
        @media (max-width:767px){
            padding-bottom:5vh;
        }
    }

    &__overlay {
        height:100%;
        width:100%;
        position: absolute;
        top:0;
        left:0;
        background: rgb(0,0,0);
        background: var(--overlay-image-gradient);
        opacity: 1;
        z-index:1;
        transition: all 0.3s ease-out;
        // border-radius: var(--border-radius-img-default);
    }
    &--rounded {
        border-radius: var(--border-radius-img-default);
        .m-image-highlight__overlay {
            border-radius: var(--border-radius-img-default);
        }
    }
    &__tag {
        @include eyebrow--white;
        z-index: 5;
        margin-bottom: 0;
    }
    &__title {
        @include heading-1;
        color:var(--text-body-text-inverse);
        text-decoration: none;
        z-index: 5;
        max-width: 20em;
     }
    &__title--heading-4 {
        @include heading-4;
    }

     &__title--wide {
        @include heading-3;
        max-width:80%;
     }
    &__text {
        @include body;
        z-index: 5;
        max-width:50rem;
        margin-top:var(--spacing-md);
        margin-bottom: var(--spacing-xxl);
        color:var(--text-body-text-inverse);
    }
    &__text--body{
        @include body;
    }
     &__button {
        z-index: 5;
     }
     &__link {
        z-index: 5;
        color:var(--text-body-text-inverse);
        border-bottom: solid 1px rgba(255,255,255,0);
        transition: color 0.3s ease-out, text-decoration 0.3s ease-out;
     }

     &__description {
         display: none;
         color:var(--text-body-text-inverse);
         z-index: 5;
         text-align: center;
     }
}


.m-image-highlight:hover {
    -webkit-box-shadow: var(--shadow-light);
    box-shadow: var(--shadow-light);
    .m-image-highlight__overlay {
        background: rgb(0,0,0);
        background: var(--overlay-image-gradient-hover);
        opacity: 1;
    }
    .m-image-highlight__text {
        color:var(--text-body-text-inverse) !important;
    }
    .m-image-highlight__link {
        color:var(--text-body-text-inverse) !important;
        text-decoration: underline;
    }
}
