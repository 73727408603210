
//mobile layout

// <800 screen see media query
.o-footer__grid {
    // width:100%;
    display: grid;
    grid-auto-flow: row;
    grid-gap: var(--spacing-md);
    align-items: start;
    padding-top: var(--spacing-3xl);
    padding-bottom: var(--spacing-xl);
    padding-left: var(--spacing-xxl);
    padding-right: var(--spacing-xxl);

    color:var(--text-body-text-inverse);
    margin-left: var(--spacing-md);
    margin-right: var(--spacing-md);

 }
    .o-footer__left-edge {
        display:flex;
        color: var(--text-body-text-inverse);
        font-size:var(--font-sm-size);
        grid-row-start: 11;
    }

    .o-footer__top-center {
        display: none;
    }
    .o-footer__top-right {
        display: none;
    }



//@large screens 800>
@media (min-width: 993px) {
    .o-footer__grid {
        display: grid;
        grid-template-areas:
        "left-top . top-center top-middle top-right top-right top-right"
        "left-middle . bottom-1 bottom-2 bottom-3 bottom-4 bottom-5"
        "left-below . bottom-1 bottom-2 bottom-3 bottom-4 bottom-5"
        "left-below . bottom-1 bottom-2 bottom-3 bottom-4 bottom-5"
        "left-edge left-edge left-edge left-edge bottom-3 bottom-4 bottom-5" ;
        grid-template-columns: .75fr 2rem repeat(5,minmax(0,.75fr));
        // grid-template-rows: 75px 1fr 75px 100px 100px;
        grid-template-rows: var(--footer-grid-template-rows);
        grid-gap: 1rem;
        align-items: start;
    }
    //logo cell
    .o-footer__left-top {
        grid-area: left-top;
    }

    //address cell
    .o-footer__left-middle {
        grid-area: left-middle;
    }
    //social media heading cell
    .o-footer__left-above {
        grid-area: left-below;
    }

    //social links cell
    .o-footer__left-below {
        display: flex;
        grid-area: left-below;
    }
    .o-footer__copyright {
        white-space: nowrap;
    }

    //copyright and privacy policy cell
    .o-footer__left-edge {
        display:flex;
        flex-wrap:nowrap;
        color: var(--text-body-text-inverse);
        white-space: pre;
        font-size:var(--font-sm-size);
        grid-area: left-edge;
        align-self: center;
    }
    //search label cell
    .o-footer__top-center {

        display:unset;
        grid-area: top-center/ top-center / top-center / top-middle;
        //padding-left: 1rem;
    }

    //search form  cells
    .o-footer__top-right {
        display:unset;
        grid-area: top-middle/ top-middle/ top-middle/ top-right;
        margin-left:200px;
        max-width: 70%;

    }
    //services column
    .o-footer__bottom-1{
        grid-area: bottom-1;
    }

    //Neurosolutions column

    .o-footer__bottom-2 {
        grid-area: bottom-2;
    }
    //industries column
    .o-footer__bottom-3 {
        grid-area: bottom-3;

    }
    //resources column
    .o-footer__bottom-4 {
        grid-area: bottom-4;

    }

    //about us column
    .o-footer__bottom-5 {
        grid-area: bottom-5;
    }

    .o-footer__link-list.extraRow__space {
        margin-bottom: 2rem;
    }

}



// address atom
.a-address {
    @include body;
    margin-bottom: 0;
}

.a-telephone {
    @include body;
    color:var(--text-body-text-inverse);
    margin-top: 10px;
    display: inline-block;
}

.a-telephone:hover {
    color:var(--text-body-text-inverse);
    text-decoration: underline;
}
.o-footer-social-row__link {
    flex-basis:32px;
    margin-top: var(--spacing-xxl);
    margin-right: var(--spacing-xs);
}

.o-footer-social-row__icon {
    width: 16px;
    height: 16px;
}

.logo__svg > svg {
    height: 40px;
}

.o-footer__submit-btn {

    background-color: #0066B4;
    position: absolute;
        right:0;
        top:0;
        background-image: none;
        border:0;
        position: absolute;
        padding-right: 10px;
        padding-left: 10px;
        height:42px;
}
.o-footer__submit-btn:focus {
    @include focused-no-offset;
}




.o-footer-search-form__submit-btn-img {
    height: 30px!important;
    width: 30px !important;
}

.o-footer{
    background-color: var(--surface-high-contrast);
    padding-bottom: var(--spacing-md);

    color:var(--text-body-text-inverse);

    @media (max-width:993px)
    {
        padding-top:1rem;
    }
    &__link {
        color:var(--text-body-text-inverse);
    }
    &__link:hover {
        color:var(--text-body-text-inverse);
        text-decoration: underline;
    }

    &__button {
        background-color: var(--dark-sapphire);
        border: 2px solid #ffffff;
        margin: 20px 0 20px;
    }

    &__copyright
    {
        color: var(--text-body-text-inverse);
        font-size:var(--font-sm-size);

    }

    &__heading
    {

       @include eyebrow--light-blue;

    }
    &__heading-2{
        font-size: var(--font-xxl-size);
        font-weight: var(--font-default-weight);

    }

    &__heading-sub {
        @include eyebrow;
        font-family: var(--heading-fonts);
        z-index: 5;
        color:var(--text-body-text-inverse);
        //border-bottom: solid 1px rgba(255,255,255,0);
       // transition: all 0.3s ease-out;
        margin-bottom: 1rem;
    }

    &__heading-sub:hover{
        color:var(--text-body-text-inverse) !important;
        text-decoration: underline;
    }

    &__input {
        background-color: #002A5D;
        color: var(--text-body-text-inverse);
        border: none;
    }

    &__input[type=text] {
        color: var(--text-body-text-inverse);
        font-size: var(--spacing-md);
        font-weight: var(--font-bold-weight);
        border:solid 1px $dark-blue;

    }
    &__input[type=text]::placeholder {
        opacity:0.7;
        font-size:var(--spacing-md);
        color:var(--text-body-text-inverse);

    }
    &__link-list
    {
        margin-top:var(--spacing-xs);
        margin-bottom:var(--spacing-xs);
        margin-left:0;
        padding-left:0;
        list-style: url('');
    }
    &__link-list-item
    {
        font-size: var(--font-md-size);
        margin-left:0;
        padding-left:0;
        margin-top:var(--spacing-sm);
        margin-bottom:var(--spacing-sm);
        line-height:var(--line-height-condensed);
        list-style-type: none;
        list-style-image: url('');
        @media (max-width:993px)
        {
            margin-bottom: var(--spacing-sm);
        }
        &--group-title {
            font-weight:var(--font-bold-weight);
            margin-top: var(--spacing-lg);
        }
    }
    &__link-list-link
    {
        font-size: var(--font-sm-size);
        color:var(--text-body-text-inverse);
    }
    &__link-list-heading-link
    {
        color:var(--text-body-text-inverse);
    }
    &__link-list-heading-link:hover
    {
        color:var(--text-body-text-inverse) !important;
        text-decoration: underline;
    }
    &__link-list-link:hover
    {
        color:var(--text-body-text-inverse) !important;
        text-decoration: underline;
    }

    &__logo-svg {
        height:var(--sizing-footer-logo);
        display: inline-block;
    }

    &__logo-svg>svg {
        height:var(--sizing-footer-logo);
    }

    &__logo-svg path {
        fill:var(--text-body-text-inverse) !important;
    }
    &__address-link {
        display: block;
        color: var(--text-body-text-inverse);
    }

}

//REVIEW

